import React, { useState } from "react";
import "./style2.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-json"; 
import "prismjs/themes/prism.css"; 
import Editor from "react-simple-code-editor";
import "prismjs/components/prism-json"; 
import "prismjs/themes/prism-okaidia.css"; 
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

function ImageUploadAndDisplay() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [isPdf, setIsPdf] = useState(false);
  const [code, setCode] = useState('{\n  "json": "example"\n}');
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    const fileIsPdf = file.type === 'application/pdf';
    setIsPdf(fileIsPdf);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (fileIsPdf) {
      reader.readAsDataURL(file);
    } else {
      reader.readAsDataURL(file); 
    }
  };

  const handleSubmit = async () => {
    if (!selectedImage) {
      alert("Please select a file first.");
      return;
    }
  
    const formData = new FormData();
    const formDataKey = isPdf ? "pdf" : "invoicee"; 
    formData.append(formDataKey, selectedImage);
  
    setIsLoading(true);
    const apiUrl = isPdf
    ? process.env.REACT_APP_API_URL_PDF_UPLOAD
    : process.env.REACT_APP_API_URL_IMAGE_UPLOAD;
  try {
    const response = await fetch(
      apiUrl,
      {
        method: "POST",
        body: formData,
      }
    );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setResponseData(data);
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file.");
    } finally {
      setIsLoading(false);
    }
  };
  console.log("responseData", responseData);
  const handleExportToCSV = async () => {
    if (
      !responseData ||
      !responseData.Items ||
      responseData.Items.length === 0
    ) {
      alert("No data to export");
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Exported Data");
    const items = responseData.Items;
    worksheet.columns = Object.keys(items[0]).map((key) => ({
      header: key.toUpperCase(),
      key: key,
      width: 20,
    }));

    items.forEach((item) => {
      worksheet.addRow(item);
    });

    const buffer = await workbook.csv.writeBuffer();
    const blob = new Blob([buffer], {
      type: "text/csv;charset=utf-8;",
    });
    const fileName = `exported_data_${new Date()
      .toISOString()
      .replace(/[:\-T.]/g, "")
      .slice(0, -4)}.csv`;

    saveAs(blob, fileName);
  };
  React.useEffect(() => {
    if (!isLoading && responseData) {
      setCode(JSON.stringify(responseData, null, 2));
    }
  }, [isLoading, responseData]);

  return (
    <>
      <nav className='navbar'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className='image-upload'>
              <input
                style={{ fontSize: "20px" }}
                type='file'
                onChange={handleImageChange}
              />
            </div>
            <button
              style={{
                fontSize: "20px",
                width: "100%",
                minWidth: "max-content",
                marginTop: "10px",
              }}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Upload File
            </button>
          </div>
          <div>
            <button
              onClick={handleExportToCSV}
              style={{ fontSize: "20px", marginTop: "10px" }}
            >
              Export to CSV
            </button>
          </div>
        </div>
      </nav>

      <div className="content">
        <div className="image-upload-section">
        {imagePreviewUrl && !isPdf ? (
            <img src={imagePreviewUrl} alt="Preview" className="image-preview" />
          ) : imagePreviewUrl && isPdf ? (
            <iframe src={imagePreviewUrl} className="pdf-preview" style={{ width: '100%', height: '500px', border: 'none' }}></iframe>
          ) : (
            <p>No file selected</p>
          )}
        </div>
        <div className='editor-section'>
          {isLoading ? (
            <div className='spinner'></div>
          ) : (
            <Editor
              value={code}
              onValueChange={setCode}
              highlight={(code) => highlight(code, languages.json)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                color: "white",
                backgroundColor: "#272822",
                height: "100%",
                width: "100%",
                border: "none",
                overflowY: "auto",
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ImageUploadAndDisplay;
