import logo from './logo.svg';

import ImageUploadAndDisplay from './component/imgUpload';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <ImageUploadAndDisplay />
      </header>
    </div>
  );
}

export default App;
